<template>
  <div>
    <multiselect
      v-model="selected"
      :options="options"
      placeholder="Type to search"
      track-by="name"
      label="name"
      :max-height="125"
      :allow-empty="allowEmpty"
      :multiple="isMultiple"
      :disabled="isDisable"
      :select-label="''"
      deselect-label=""
      :searchable="isSearchable"
      :custom-label="customLabel"
    >
      <span slot="noResult">Oops! No data found</span>
    </multiselect>
  </div>
</template>
<script>
import { RepositoryFactory } from "@/repositories/repositoryFactory";
const MasterRepository = RepositoryFactory.get("master");
export default {
  props: {
    isDisable: {
      type: Boolean,
      default: false,
    },
    isGetData: {
      type: Boolean,
      default: true,
    },
    isMultiple: {
      type: Boolean,
      default: false,
    },
    allowEmpty: {
      type: Boolean,
      default: true,
    },
    isSearchable: {
      type: Boolean,
      default: true,
    },
    showAllLabel: {
      type: Boolean,
      default: false,
    },
    params:null,
  },
  data() {
    return {
      options: [],
      selected: null,
    };
  },
  methods: {
    customLabel(option) {
      return `${option.code} - ${option.name}`;
    },
    async setSelectedData(data) {
      this.selected = data;
    },
    async getData(params_get) {
      this.$vs.loading();
      if (params_get) {
        this.params = params_get;
      }
      console.log("this.params", this.params)
      console.log("params_get", params_get)
      try {
        const resp = await MasterRepository.getTerritories(params_get);
        if (resp.code == 200) {
          var options = [];
          if (this.showAllLabel && this.selected == null) {
            options.push({
              id: 0,
              code: "All",
              name: "Territory",
            });
            this.selected = options[0];
          }
          resp.data.records.forEach(function (item) {
            options.push(item);
          }, options);
          this.options = options;
          if (this.params != null) {
            if (this.params.params.id) {
              this.selected = options[0];
            }
            if (this.params.params.selected) {
              this.selected = [];
              console.log("this.params.params.selected", this.params.params.selected)
              this.params.params.selected.forEach(terId => {
                options.forEach(optTer => {
                  if (optTer.id == terId) {
                    this.selected.push(optTer);
                  }
                });
              });
            }
          }
          if (params_get != null) {
            if (params_get.params.id) {
              this.selected = options[0];
            }
            if (params_get.params.selected) {
              this.selected = [];
              console.log("params_get.params.selected", params_get.params.selected)
              params_get.params.selected.forEach(terId => {
                options.forEach(optTer => {
                  if (optTer.id == terId) {
                    this.selected.push(optTer);
                  }
                });
              });
            }
          }
        }
      } catch (error) {
        this.$vs.notify({
          title: "Error",
          text: "Error while fetching territories",
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
        console.log(error);
      }
      this.$vs.loading.close();
    },
  },
  watch: {
    selected(v) {
      // check v is array or not
      if (Array.isArray(v)) {
        if (v.length > 1) {
          // remove ID = 0 from selected
          for (var i = 0; i < v.length; i++) {
            if (v[i].id == 0) {
              v.splice(i, 1);
            }
          }
        }
      }

      this.selected = v;
      this.$emit("data", v);
    },
  },
  mounted() {
    if (this.isGetData) {
      let params = this.params != null ? this.params : null;
      this.getData(params);
    }
  },
};
</script>
